import Container from 'react-bootstrap/Container';
import { Button, Navbar, Dropdown, ButtonGroup } from 'react-bootstrap';
import { FaSignOutAlt, FaBars } from 'react-icons/fa';
import { DiAptana } from 'react-icons/di';
import { useAuth } from '../Private/AuthContext';
import BreadcrumbComponent from '../Breadcrumb/BreadcrumbComponent';
import './Header.css';

interface HeaderProps {
  onToggleSidebar: () => void;
}

export function Header({ onToggleSidebar }: HeaderProps) {
  const { isAuthenticated, logout } = useAuth();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <header>
      <Navbar
        expand="lg"
        className="navbar border-bottom border-body fixed-top bg-white shadow-sm"
      >
        <Container
          fluid
          className="d-flex justify-content-between align-items-center"
        >
          <div className="d-flex align-items-center">
            <Button
              variant="outline-secondary"
              className="d-md-none d-lg-none" // Esconde em telas md e acima
              onClick={onToggleSidebar}
            >
              <FaBars size={18} />
            </Button>
            <Navbar.Brand href="/" className="me-2">
              <img
                src="/main.png"
                className="navbar-brand-img"
                alt="Logo"
                width="50px"
              />
            </Navbar.Brand>
            <BreadcrumbComponent /> {/* Breadcrumb aqui */}
          </div>
          <div className="d-flex align-items-center">
            <Dropdown as={ButtonGroup} className="me-2">
              <Button
                variant="outline-primary"
                className="d-flex align-items-center"
              >
                <DiAptana size={18} className="me-2" />
                Administração
              </Button>
              <Dropdown.Toggle
                split
                variant="outline-primary"
                id="dropdown-split-basic"
              />
              <Dropdown.Menu>
                <Dropdown.Item
                  href="/Admin"
                  className="d-flex align-items-center"
                >
                  <DiAptana size={18} className="me-2" />
                  Usuários
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown as={ButtonGroup} className="me-2">
              <Button
                variant="outline-secondary"
                className="d-flex align-items-center"
              >
                <DiAptana size={18} className="me-2" />
                Configuração
              </Button>
              <Dropdown.Toggle
                split
                variant="outline-secondary"
                id="dropdown-split-basic"
              />
              <Dropdown.Menu>
                <Dropdown.Item
                  href="/Contas"
                  className="d-flex align-items-center"
                >
                  <DiAptana size={18} className="me-2" />
                  Contas
                </Dropdown.Item>
                <Dropdown.Item
                  href="/Integracao"
                  className="d-flex align-items-center"
                >
                  <DiAptana size={18} className="me-2" />
                  Integração
                </Dropdown.Item>
                <Dropdown.Item
                  href="/Usuario"
                  className="d-flex align-items-center"
                >
                  <DiAptana size={18} className="me-2" />
                  Meu Perfil
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              onClick={logout}
              variant="outline-danger"
              className="d-flex align-items-center"
            >
              <FaSignOutAlt size={18} className="me-2" />
              Sair
            </Button>
          </div>
        </Container>
      </Navbar>
    </header>
  );
}
