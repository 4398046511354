import React from 'react';
import { Nav, Button } from 'react-bootstrap';
import {
  FaChartLine,
  FaCubes,
  FaAdjust,
  FaShoppingCart,
  FaReceipt,
  FaSignOutAlt,
  FaFileAlt,
  FaPlusCircle,
  FaClipboardList,
  FaThList,
  FaBullhorn,
  FaBalanceScale,
} from 'react-icons/fa';
import { useAuth } from '../Private/AuthContext';
import './Sidebar.css';

interface SidebarProps {
  isOpen: boolean;
  onToggle: () => void;
}

export const Sidebar: React.FC<SidebarProps> = ({ isOpen, onToggle }) => {
  const { isAuthenticated, logout } = useAuth();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <Nav className="flex-column sidebar-content">
          <Nav.Link href="/" className="d-flex align-items-center">
            <FaChartLine />
            <span className="nav-text">Dashboard</span>
          </Nav.Link>

          <div className="nav-item dropdown">
            <Nav.Link
              href="/Anuncios"
              className="d-flex align-items-center dropdown-toggle"
            >
              <FaBullhorn />
              <span className="nav-text">Anúncios</span>
            </Nav.Link>
            <div className="dropdown-menu">
              {/* <Nav.Link
                href="/anuncios/cadastrar"
                className="d-flex align-items-center"
              >
                <FaPlusCircle />
                <span className="nav-text">Criar Anúncio</span>
              </Nav.Link> */}
              <Nav.Link href="/anuncios" className="d-flex align-items-center">
                <FaClipboardList />
                <span className="nav-text">Gerenciar Anúncios</span>
              </Nav.Link>
              <Nav.Link
                href="/anuncios-vs-estoque"
                className="d-flex align-items-center"
              >
                <FaBalanceScale />
                <span className="nav-text">Anúncios Ativos vs Estoque</span>
              </Nav.Link>

              <Nav.Link
                href="/anunciospaused-vs-estoque"
                className="d-flex align-items-center"
              >
                <FaBalanceScale />
                <span className="nav-text">Anúncios Pausados vs Estoque</span>
              </Nav.Link>
            </div>
          </div>

          <div className="nav-item dropdown">
            <Nav.Link
              href="/Produtos"
              className="d-flex align-items-center dropdown-toggle"
            >
              <FaCubes />
              <span className="nav-text">Produtos</span>
            </Nav.Link>
            <div className="dropdown-menu">
              <Nav.Link
                href="/produtos/cadastrar"
                className="d-flex align-items-center"
              >
                <FaPlusCircle />
                <span className="nav-text">Criar Produto</span>
              </Nav.Link>
              <Nav.Link href="/produtos" className="d-flex align-items-center">
                <FaClipboardList />
                <span className="nav-text">Todos os Produtos</span>
              </Nav.Link>
              <Nav.Link
                href="/produtos/categorias"
                className="d-flex align-items-center"
              >
                <FaThList />
                <span className="nav-text">Categorias</span>
              </Nav.Link>
            </div>
          </div>

          <Nav.Link href="/Estoque" className="d-flex align-items-center">
            <FaAdjust />
            <span className="nav-text">Estoque</span>
          </Nav.Link>
          <Nav.Link href="/Compras" className="d-flex align-items-center">
            <FaShoppingCart />
            <span className="nav-text">Compras</span>
          </Nav.Link>

          {/* Submenu para Vendas */}
          <div className="nav-item dropdown">
            <Nav.Link
              href="/Vendas"
              className="d-flex align-items-center dropdown-toggle"
            >
              <FaReceipt />
              <span className="nav-text">Vendas</span>
            </Nav.Link>
            <div className="dropdown-menu">
              <Nav.Link href="/vendas" className="d-flex align-items-center">
                <FaPlusCircle />
                <span className="nav-text">Vendas do Mercado Livre</span>
              </Nav.Link>
              <Nav.Link
                href="/vendas/vendaslocal"
                className="d-flex align-items-center"
              >
                <FaClipboardList />
                <span className="nav-text">Todas as Vendas Integradas</span>
              </Nav.Link>
              <Nav.Link
                href="/relatorios/relatoriosVenda"
                className="d-flex align-items-center"
              >
                <FaFileAlt />
                <span className="nav-text">Relatórios de Vendas</span>
              </Nav.Link>
            </div>
          </div>

          <Nav.Link href="/Relatorios" className="d-flex align-items-center">
            <FaFileAlt />
            <span className="nav-text">Relatórios</span>
          </Nav.Link>
        </Nav>

        <div className="sidebar-footer">
          <Button
            onClick={logout}
            variant="outline-danger"
            className="d-flex align-items-center"
            style={{ marginLeft: '20px' }}
          >
            <FaSignOutAlt />
            <span className="nav-text">Sair</span>
          </Button>
        </div>
      </div>
      <div
        className={`sidebar-overlay ${isOpen ? 'show' : ''}`}
        onClick={onToggle}
      ></div>
    </>
  );
};

export default Sidebar;
