import axios from 'axios';
import { toast } from 'react-toastify';

interface TokenData {
  id: string;
  access_token: string;
  refresh_token: string;
  updated_at: string;
  expires_in?: number;
}

export const refreshToken = async (
  meliContaId: string,
  setLoading: (loading: boolean) => void
): Promise<void> => {
  try {
    setLoading(true); // Inicia o estado de carregamento

    const response = await axios.get<{ data: TokenData }>(
      `${
        import.meta.env.VITE_APP_API_URL
      }/meli-contas/${meliContaId}/meli-tokens`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
        },
      }
    );

    const tokenData = response.data.data;

    if (tokenData) {
      // Faz a solicitação para atualizar o token
      const refreshResponse = await axios.post<{
        user_id: any;
        access_token: string;
        refresh_token: string;
        expires_in: number;
      }>(
        'https://api.mercadolibre.com/oauth/token',
        {
          grant_type: 'refresh_token',
          client_id: import.meta.env.VITE_APP_CLIENT_ID,
          client_secret: import.meta.env.VITE_APP_CLIENT_SECRET,
          refresh_token: tokenData.refresh_token,
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      const newTokenData = refreshResponse.data;

      // Atualiza o token no banco de dados
      await axios.put(
        `${import.meta.env.VITE_APP_API_URL}/meli-tokens/${tokenData.id}`,
        {
          access_token: newTokenData.access_token,
          refresh_token: newTokenData.refresh_token,
          expires_in: 21600,
          user_mercado_livre_id: newTokenData.user_id,
          tipo: 'refresh',
          token_type: 'Bearer',
          meliConta_id: meliContaId,
          updated_at: new Date().toISOString(), // Usa a data atual
        },
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Atualiza o token e user_id no sessionStorage
      sessionStorage.setItem('ml_access_token', newTokenData.access_token);
      sessionStorage.setItem(
        'ml_token_expiry',
        new Date(Date.now() + newTokenData.expires_in * 1000).toString()
      );
      sessionStorage.setItem('ml_user_id', newTokenData.user_id);
    } else {
      toast.error(
        'Nenhuma integração encontrada para a conta selecionada. Faça a autenticação.'
      );
    }
  } catch (error) {
    console.error('Erro ao atualizar o token:', error);
    toast.error('Erro ao atualizar o token');
  } finally {
    setLoading(false); // Finaliza o estado de carregamento
  }
};

export const refreshTokenWithLoading = async (
  meliContaId: string,
  setLoading: ((loading: boolean) => void) | null
): Promise<void> => {
  if (setLoading) {
    await refreshToken(meliContaId, setLoading);
  } else {
    // Se `setLoading` for null, chamamos `refreshToken` com uma função dummy que não faz nada
    await refreshToken(meliContaId, () => {});
  }
};
