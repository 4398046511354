const NotAuthorized = () => {
  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh', 
      textAlign: 'center'
    }}>
      <h1>Você não está autorizado a acessar esta página.</h1>
    </div>
  );
};

export default NotAuthorized;
