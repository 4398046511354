import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Spinner, Alert } from 'react-bootstrap';

interface Token {
  id: number;
  meliConta_id: number;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  created_at: string;
  updated_at: string;
  accountName?: string; // Novo campo para o nome da conta
}

interface Account {
  id: number;
  nome: string;
}

interface TokensProps {
  updated: boolean;
}

const Tokens: React.FC<TokensProps> = ({ updated }) => {
  const [tokens, setTokens] = useState<Token[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchTokens();
  }, [updated]); // Recarrega os tokens quando 'updated' muda

  const fetchTokens = async () => {
    setLoading(true);
    setError(null);

    try {
      const [tokensResponse, accountsResponse] = await Promise.all([
        axios.get(import.meta.env.VITE_APP_API_URL + `/meli-tokens`, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
          }
        }),
        axios.get(import.meta.env.VITE_APP_API_URL + `/meli-contas`, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
          }
        })
      ]);

      const tokens = tokensResponse.data.data as Token[];
      const accounts = accountsResponse.data.data as Account[];

      // Combinar os tokens com os nomes das contas
      const tokensWithAccountNames = tokens.map((token: Token) => {
        const account = accounts.find((acc: Account) => acc.id === token.meliConta_id);
        return {
          ...token,
          accountName: account ? account.nome : 'Conta desconhecida'
        };
      });

      setTokens(tokensWithAccountNames);
    } catch (error) {
      console.error('Erro ao buscar tokens ou contas:', error);
      setError('Erro ao buscar tokens ou contas.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <h1 className="text-center mb-4">Tokens do Mercado Livre</h1>
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">@</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger" className="text-center">
          {error}
        </Alert>
      ) : (
        <div className="table-responsive">
          <Table hover className="table-striped">
            <thead className="thead-dark table table-dark">
              <tr>
                <th>Id Usuário</th>
                <th>Conta</th> {/* Nova coluna para o nome da conta */}
                <th>Access Token</th>
                <th>Refresh Token</th>
                <th>Expires In</th>
                <th>Data de Criação</th>
                <th>Última Atualização</th>
              </tr>
            </thead>
            <tbody>
              {tokens.map((token: Token) => (
                <tr key={token.id}>
                  <td>{token.meliConta_id}</td>
                  <td>{token.accountName}</td> {/* Exibir o nome da conta */}
                  <td>{token.access_token}</td>
                  <td>{token.refresh_token}</td>
                  <td>{token.expires_in}</td>
                  <td>{new Date(token.created_at).toLocaleString('pt-BR', { dateStyle: 'short', timeStyle: 'short' })}</td>
                  <td>{new Date(token.updated_at).toLocaleString('pt-BR', { dateStyle: 'short', timeStyle: 'short' })}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default Tokens;
