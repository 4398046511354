import React, { useEffect, useState, ReactNode } from 'react';
import axios from 'axios';

interface PermissionWrapperProps {
  screenId: string;
  children: ReactNode;
}

interface ChildProps {
  permission?: string;
}

interface PermissionButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  permission: string;
}

interface PermissionTableProps
  extends React.TableHTMLAttributes<HTMLTableElement> {
  permission: string;
}

const PermissionButton: React.FC<PermissionButtonProps> = ({
  children,
  permission,
  ...props
}) => <button {...props}>{children}</button>;

const PermissionTable: React.FC<PermissionTableProps> = ({
  children,
  permission,
  ...props
}) => <table {...props}>{children}</table>;

const PermissionWrapper: React.FC<PermissionWrapperProps> = ({
  screenId,
  children,
}) => {
  const [userPermissions, setUserPermissions] = useState<string[]>([]);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const token = sessionStorage.getItem('authToken');
        const userId = sessionStorage.getItem('userId'); // Assumindo que você armazena o ID do usuário no sessionStorage
        const response = await axios.get<{ permissions: string[] }>(
          `${
            import.meta.env.VITE_APP_API_URL
          }/screens/${screenId}/permissions/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserPermissions(response.data.permissions || []);
      } catch (error) {
        console.error('Erro ao obter as permissões do usuário:', error);
      }
    };

    fetchUserPermissions();
  }, [screenId]);

  const hasPermission = (permission: string): boolean => {
    return userPermissions.includes(permission);
  };

  return (
    <>
      {React.Children.map(children, (child) => {
        if (!React.isValidElement<ChildProps>(child)) {
          return child;
        }

        const requiredPermission = child.props.permission;

        if (requiredPermission && !hasPermission(requiredPermission)) {
          return null;
        }

        return child;
      })}
    </>
  );
};

export { PermissionWrapper, PermissionButton, PermissionTable };
