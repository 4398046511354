import { ReactNode } from 'react';
import NotAuthorized from './NotAuthorized';

interface AdminRouteProps {
  children: ReactNode;
}

const AdminRoute = ({ children }: AdminRouteProps) => {
  const userRole = sessionStorage.getItem('role');

  if (userRole !== 'admin') {
    return <NotAuthorized />;
  }

  return <>{children}</>;
};

export default AdminRoute;
