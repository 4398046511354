import { Breadcrumb } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import './BreadcrumbComponent.css';  // Importe o arquivo CSS

const BreadcrumbComponent = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <div className="breadcrumb-container">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;

          return (
            <Breadcrumb.Item key={to} href={to} active={index === pathnames.length - 1}>
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default BreadcrumbComponent;
